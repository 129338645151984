section {
    position: relative;
}

#root {
    background: transparent linear-gradient(180deg, #FFFFFF 20%, #FFFDF0 100%) 0% 0% no-repeat padding-box;
    min-height: 100vh;
    /* height: 100%; */
}

.app-header {
    background-color: black;
    height: 250px;
}

.header-background {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.header-background img {
    max-height: 88%;
    top: 6%;
}

.header-background .left {
    position: absolute;
    right: calc(50% + 150px);
}

.header-background .right {
    position: absolute;
    left: calc(50% + 150px);
}

.header-box {
    position: absolute;
    top: 0;
    left: calc(50% - 150px);
    text-align: center;
    color: #FCFFE3;
    z-index: 100;
    background-color: #BE0000;
    width: 300px;
    height: 280px;
}

.app-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
}

.flex {
    display: flex;
}

.container-padding {
    margin: 3em 4em;
}

.app-column {
    width: 50%;
    max-width: 550px;
}

.column-padding {
    padding: 1.5em;
}

.column-left p:first-of-type {
    margin-top: 0.5em;
}

.column-right {
    margin-top: 34px;
}

.workplaces {
    text-align: left;
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 1.5em;
}

.workspaces li {
    font-weight: 400;
}

h1 {
    font-family: semplicitapro, sans-serif;
    font-weight: 400;
    font-size: 90px;
    letter-spacing: 6.54px;
}

.contact {
    position: absolute;
    top: 1.5em;
    right: 3em;
    color: #DB0707;
    text-transform: uppercase;
    text-decoration: unset;
    font-family: semplicitapro, sans-serif;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 1.5px;
}

.list-column {
    width: 50%;
}

a.inline-link {
    color: #BE0000;
    text-decoration: none;
}

h2 {
    font-family: semplicitapro, sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    font-style: bold;
    font-size: 1.1em;
    text-transform: uppercase;
    margin: 1.5em 0 0.2em 0;
}

h2.faded {
    color: #767171;
}

p {
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
}

.app-footer {
    padding: 4em 2em 10em;
    max-width: 1400px;
    margin: auto;
}

hr {
    height: 3px;
    border-color: transparent;
    background: transparent linear-gradient(270deg, #980000 0%, #BE0000 58%, #980000 100%) 0% 0% no-repeat padding-box;
    /* background: transparent linear-gradient(270deg, #000000 0%, #BE0000 58%, #000000 100%, #000000 100%) 0% 0% no-repeat padding-box; */
}

img.nicole-working {
    padding: 0 1em 0 0;
    width: 100px;
    object-fit: contain;
    max-height: 100px;
}

img.portrait {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 30px;
    width: 200px;
    height: auto;
}

.flex-container {
    display: flex;
    position: relative;
}

.flex-container p {
    margin: 0;
}

.small-text {
    font-size: 14px;
    margin-top: 0;
}

.portfolio {
    max-width: 380px;
}

#copyright {
    font-size: .7em;
}

@media screen and (max-width: 992px) {
    .app-header {
        height: 200px;
    }
    .header-background .left {
        right: calc(50% + 120px);
    }
    .header-background .right {
        left: calc(50% + 120px);
    }
    .header-box {
        left: calc(50% - 120px);
        width: 240px;
        height: 225px;
    }
    h1 {
        font-size: 75px;
        letter-spacing: 6.54px;
    }
    .container {
        display: block;
    }
    .app-column {
        width: unset;
    }
    .container-padding {
        margin: 3em 0;
    }
    .column-padding {
        padding: 1em 2em 0;
    }
    .column-right {
        margin-top: 0;
        padding-top: 0;
    }
}

@media screen and (max-width: 750px) {
    .app-header {
        height: 150px;
    }
    .header-background .left {
        right: calc(50% + 100px);
    }
    .header-background .right {
        left: calc(50% + 100px);
    }
    .header-box {
        left: calc(50% - 100px);
        width: 200px;
        height: 180px;
    }
    h1 {
        font-size: 60px;
        letter-spacing: 6.54px;
    }
    .contact {
        font-size: .9em;
        top: 2.2em;
        right: calc(50% - 44px);
    }
    .container>.app-column {
        margin-top: 1em;
    }
    #copyright {
        position: relative;
        text-align: center;
        bottom: -150px;
    }
}

@media screen and (max-width: 560px) {
    .flex {
        display: block;
    }
    .list-column {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .app-footer {
        padding: 4em 0em 10em;
    }
}

@media screen and (max-width: 400px) {
    .flex-container {
        margin-bottom: 140px;
    }
    img.nicole-working {
        position: absolute;
        bottom: -110px;
    }
}